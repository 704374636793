<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS CLIENTES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="fas fa-briefcase fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaClientes.length}}</span>
                            <br>
                            <span class="text-muted h6">Clientes registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de los clientes </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col cols="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalRegistroClientes = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo cliente
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Clientes registrados</span>
                </CCardHeader>

                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">

                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaClientes" :fields="camposClientes" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>

                                <template v-slot:cell(razonSocial)="data">
                                    <a :href="`${base_host}${data.item.path}/login`" target="_blank" v-if="data.item.path != null" :id="`popover-target-${data.item.idCliente}`"> {{data.value | truncate(30)}}</a>
                                    <span v-if="data.item.path == null" :id="`popover-target-${data.item.idCliente}`"> {{data.value | truncate(27)}}</span>

                                    <b-popover :target="`popover-target-${data.item.idCliente}`" triggers="hover" placement="righttop">
                                        <template #title>{{data.item.nombreComercial ?  data.item.nombreComercial : 'Sin nombre comercial'| truncate(25)}}</template>
                                        <b-row>
                                            <b-col md="12" class="text-center">
                                                <img :style="`background:${data.item.primaryColor};`" width="150px" :src="data.item.urlLogo" class=" img-responsive">
                                            </b-col>
                                            <b-col md="12" class="text-center">
                                                <span class="h6 mt-2">{{data.item.numeroRuc}}</span>
                                            </b-col>
                                        </b-row>
                                    </b-popover>
                                </template>

                                <template v-slot:cell(representanteLegal)="data">
                                    <span>{{data.item.representanteLegal | truncate(25)}}</span>
                                </template>
                                <template v-slot:cell(correo)="data">
                                    <span>{{data.item.correo | truncate(22)}}</span>
                                </template>

                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" :to="{name: 'Gestion del Cliente master', params: {id: param.item.idCliente}}">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 mb-1 text-white" v-c-tooltip.hover.click="'Anular'" @click="cambiarEstadoCliente(param)" variant="warning">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-times  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCliente(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" class="my-0 float-right" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistroClientes" :closeOnBackdrop="false" size="lg" color="dark">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo cliente </span>
            </h6>
            <CButtonClose @click="modalRegistroClientes = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCliente)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="número de ruc" :rules="{ required: true,numeric:true,min:11,max:11 }" v-slot="validationContext">
                            <b-form-group label="Número de ruc:" class="mb-2">
                                <b-overlay :show="cargaApi" opacity="0.5" spinner-small>
                                    <b-input-group>
                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese número de RUC" v-model="datosNuevoCliente.numeroRuc"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="disabledBtnApi" v-c-tooltip.hover.click="'Buscar'" variant="dark" @click="apiSunat()">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-overlay>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4">
                        <validation-provider name="razón social" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Razón social:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosNuevoCliente.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4">
                        <validation-provider name="nombre comercial" v-slot="validationContext">
                            <b-form-group label="Nombre comercial:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre comercial" v-model="datosNuevoCliente.nombreComercial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="departamento" rules="required" v-slot="{errors}">
                            <b-form-group label="Departamento:" class="mb-2">
                                <v-select :reduce="listaDepartamentos => listaDepartamentos.idDepartamento" label="nombre" @input="obtenerProvincia()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNuevoCliente.idDepartamento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNuevoCliente.idDepartamento" :options="listaDepartamentos" @search:blur="blurDepartamento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4">
                        <validation-provider name="provincia" rules="required" v-slot="{errors}">
                            <b-form-group label="Provincia:" class="mb-2">
                                <v-select :reduce="listaProvincias => listaProvincias.idProvincia" label="nombre" @input="obtenerDistrito()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNuevoCliente.idProvincia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNuevoCliente.idProvincia" :options="listaProvincias.filter(x => x.idDepartamento==datosNuevoCliente.idDepartamento)" @search:blur="blurProvincia">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4">
                        <validation-provider name="distrito" rules="required" v-slot="{errors}">
                            <b-form-group label="Distrito:" class="mb-2">
                                <v-select :reduce="listaDistritos => listaDistritos.idDistrito" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosNuevoCliente.idDistrito , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosNuevoCliente.idDistrito" :options="listaDistritos.filter(x => x.idProvincia==datosNuevoCliente.idProvincia)" @search:blur="blurDistrito">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="3">
                        <validation-provider name="localidad" v-slot="validationContext">
                            <b-form-group label="Localidad:" class="mb-2">
                                <b-form-input autocomplete="off" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosNuevoCliente.localidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="3">
                        <validation-provider name="código postal" v-slot="validationContext">
                            <b-form-group label="Código Postal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código postal" v-model="datosNuevoCliente.codigoPostal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="dirección fiscal" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Dirección fiscal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección fiscal" v-model="datosNuevoCliente.direccionFiscal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="contacto" v-slot="validationContext">
                            <b-form-group label="Contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosNuevoCliente.contacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="teléfono del contacto" :rules="{  numeric: true }" v-slot="validationContext">
                            <b-form-group label="Teléfono del contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono del contacto" v-model="datosNuevoCliente.telefonoContacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="correo del contacto" :rules="{ email: true }" v-slot="validationContext">
                            <b-form-group label="Correo del contacto:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo del contacto" v-model="datosNuevoCliente.correoContacto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4">
                        <validation-provider name="actividad económica" v-slot="validationContext">
                            <b-form-group label="Actividad económica:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el actividad económica" v-model="datosNuevoCliente.actividadEconomica"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="teléfono" :rules="{ required: true, numeric: true }" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosNuevoCliente.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="correo" :rules="{ required: true, email: true }" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosNuevoCliente.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="web" v-slot="validationContext">
                            <b-form-group label="Web:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la web" v-model="datosNuevoCliente.web"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="representante legal" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Representante legal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el representante legal" v-model="datosNuevoCliente.representanteLegal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="cargo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Cargo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cargo" v-model="datosNuevoCliente.cargo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" placeholder="Ingrese las observaciones" v-model="datosNuevoCliente.observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistroClientes = false">
                            Cancelar
                        </b-button>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import {
    CChartLineSimple,
    CChartBarSimple
} from '../charts/index.js'
import UBIGEO from "@/helpers/Ubigeo";
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import firebase from 'firebase';
const dbClientes = firebase.firestore().collection('clientes');

export default {
    components: {
        CChartLineSimple,
        CChartBarSimple
    },
    data() {
        return {
            base_host: CONSTANTES.URL_RUTA_FRONTEND,
            listaDistritos: [],
            listaProvincias: [],
            listaDepartamentos: [],
            listaClientes: [],

            cargaApi: false,
            disabledBtnApi: true,

            estadisticos: {
                estadoRegistrado: "",
                totalRegistros: "",
                estadoActivo: "",
                estadoSuspendido: "",
                estadoBaja: "",
            },
            camposClientes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Razón Social",
                    class: "text-center"
                },
                // {
                //     key: "nombreComercial",
                //     label: "Nombre Comercial",
                //     class: "text-center"
                // },
                // {
                //     key: "numeroRuc",
                //     label: "RUC",
                //     class: "text-center"
                // },
                {
                    key: "representanteLegal",
                    label: "Representante",
                    class: "text-center",
                },
                {
                    key: "telefono",
                    label: "Teléfono",
                    class: "text-center",
                },
                {
                    key: "correo",
                    label: "Correo",
                    class: "text-center",
                },

                {
                    label: "",
                    key: "opciones",
                    class: "text-center",
                },
            ],

            modalRegistroClientes: false,
            modalAfiliacionCliente: false,

            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            datosNuevoCliente: {
                idCliente: '',
                numeroRuc: '',
                razonSocial: '',
                nombreComercial: '',
                idDepartamento: null,
                idProvincia: null,
                idDistrito: null,
                localidad: '',
                codigoPostal: '',
                direccionFiscal: '',
                contacto: '',
                telefonoContacto: '',
                correoContacto: '',
                actividadEconomica: '',
                telefono: '',
                correo: '',
                web: '',
                representanteLegal: '',
                cargo: '',
                observaciones: '',
                estado: 2,
            },
            disabled: false
        }
    },
    methods: {

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },

        listarClientes() {
            let me = this;
            dbClientes.where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.listaClientes = [];
                    querySnapshot.forEach((doc) => {
                        me.listaClientes.push({
                            idCliente: doc.id,
                            numeroRuc: doc.data().numeroRuc,
                            razonSocial: doc.data().razonSocial,
                            nombreComercial: doc.data().nombreComercial,
                            idDepartamento: doc.data().idDepartamento,
                            idProvincia: doc.data().idProvincia,
                            idDistrito: doc.data().idDistrito,
                            localidad: doc.data().localidad,
                            codigoPostal: doc.data().codigoPostal,
                            direccionFiscal: doc.data().direccionFiscal,
                            contacto: doc.data().contacto,
                            telefonoContacto: doc.data().telefonoContacto,
                            correoContacto: doc.data().correoContacto,
                            actividadEconomica: doc.data().actividadEconomica,
                            telefono: doc.data().telefono,
                            correo: doc.data().correo,
                            web: doc.data().web,
                            representanteLegal: doc.data().representanteLegal,
                            cargo: doc.data().cargo,
                            observaciones: doc.data().observaciones,
                            estado: doc.data().estado,
                        });
                    });
                    me.listaClientes.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },

        blurDepartamento() {
            this.computedForm.refs.departamento.validate();
        },
        blurProvincia() {
            this.computedForm.refs.provincia.validate();
        },
        blurDistrito() {
            this.computedForm.refs.distrito.validate();
        },
        eliminarCliente(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar al cliente?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbClientes.doc(param.item.idCliente).update({
                            estado: 1,
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                        })
                        .catch((error) => {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        cambiarEstadoCliente(param) {
            let me = this;
            dbClientes.doc(param.item.idCliente).update({
                    estado: 3,
                })
                .then(() => {
                    me.swat('success', 'Anulado satisfactoriamente')
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        obtenerDepartamento() {
            let me = this;
            me.listaDepartamentos = UBIGEO.Departamentos;
        },

        obtenerProvincia() {
            let me = this;
            (me.datosNuevoCliente.idProvincia = null),
            (me.datosNuevoCliente.idDistrito = null),
            (me.listaDistritos = []),

            me.listaProvincias = UBIGEO.Provincias;
        },

        obtenerDistrito() {
            let me = this;
            (me.datosNuevoCliente.idDistrito = null),
            me.listaDistritos = UBIGEO.Distritos;
        },
        registrarCliente() {
            let me = this;
            me.disabled = true;
            dbClientes.add({
                    numeroRuc: me.datosNuevoCliente.numeroRuc,
                    razonSocial: me.datosNuevoCliente.razonSocial,
                    nombreComercial: me.datosNuevoCliente.nombreComercial,
                    idDepartamento: me.datosNuevoCliente.idDepartamento,
                    idProvincia: me.datosNuevoCliente.idProvincia,
                    idDistrito: me.datosNuevoCliente.idDistrito,
                    localidad: me.datosNuevoCliente.localidad,
                    codigoPostal: me.datosNuevoCliente.codigoPostal,
                    direccionFiscal: me.datosNuevoCliente.direccionFiscal,
                    contacto: me.datosNuevoCliente.contacto,
                    telefonoContacto: me.datosNuevoCliente.telefonoContacto,
                    correoContacto: me.datosNuevoCliente.correoContacto,
                    actividadEconomica: me.datosNuevoCliente.actividadEconomica,
                    telefono: me.datosNuevoCliente.telefono,
                    correo: me.datosNuevoCliente.correo,
                    web: me.datosNuevoCliente.web,
                    representanteLegal: me.datosNuevoCliente.representanteLegal,
                    cargo: me.datosNuevoCliente.cargo,
                    observaciones: me.datosNuevoCliente.observaciones,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistroClientes = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        apiSunat() {
            let me = this;
            let mi_token = "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.cargaApi = true;
            axios
                .get("https://apiperu.dev/api/ruc/" + me.datosNuevoCliente.numeroRuc, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.datosNuevoCliente.razonSocial = response.data.data.nombre_o_razon_social;
                        me.datosNuevoCliente.direccionFiscal = response.data.data.direccion;
                        me.datosNuevoCliente.idDepartamento = response.data.data.ubigeo[0]
                        me.obtenerProvincia();
                        me.datosNuevoCliente.idProvincia = response.data.data.ubigeo[1]
                        me.obtenerDistrito();
                        me.datosNuevoCliente.idDistrito = response.data.data.ubigeo[2]
                    } else {
                        me.swat('error', 'No se encontró el número de ruc!')
                    }
                    me.cargaApi = false;
                })
                .catch((error) => {
                    me.cargaApi = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        resertModalRegistroClientes() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosNuevoCliente.numeroRuc = '',
                this.datosNuevoCliente.razonSocial = '',
                this.datosNuevoCliente.nombreComercial = '',
                this.datosNuevoCliente.idDepartamento = null,
                this.datosNuevoCliente.idProvincia = null,
                this.datosNuevoCliente.idDistrito = null,
                this.datosNuevoCliente.localidad = '',
                this.datosNuevoCliente.codigoPostal = '',
                this.datosNuevoCliente.direccionFiscal = '',
                this.datosNuevoCliente.contacto = '',
                this.datosNuevoCliente.telefonoContacto = '',
                this.datosNuevoCliente.correoContacto = '',
                this.datosNuevoCliente.actividadEconomica = '',
                this.datosNuevoCliente.telefono = '',
                this.datosNuevoCliente.correo = '',
                this.datosNuevoCliente.web = '',
                this.datosNuevoCliente.representanteLegal = '',
                this.datosNuevoCliente.cargo = '',
                this.datosNuevoCliente.observaciones = ''
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistroClientes: function (val) {
            if (val == false) {
                this.resertModalRegistroClientes();
            }
        },
        'datosNuevoCliente.numeroRuc': function (val) {
            if (val.length == 11) {
                this.disabledBtnApi = false
            } else {
                this.disabledBtnApi = true
            }
        },
    },
    mounted() {
        this.listarClientes();
        this.obtenerDepartamento();
    }
}
</script>
